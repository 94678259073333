import { ethers } from 'ethers'

const TOKENS = {
  1101: [
    // polygon zkEVM
    {
      name: 'Ethereum',
      symbol: 'ETH',
      decimals: 18,
      address: ethers.constants.AddressZero,
      coingeckoUrl: 'https://www.coingecko.com/en/coins/ethereum',
      isNative: true,
      isShortable: true,
      displayDecimals: 2,
      rewardDisplayDecimals: 4,
    },
    {
      name: 'Wrapped Ether',
      symbol: 'WETH',
      decimals: 18,
      address: '0x4F9A0e7FD2Bf6067db6994CF12E4495Df938E6e9',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/ethereum',
      isWrapped: true,
      baseSymbol: 'ETH',
      displayDecimals: 2,
      rewardDisplayDecimals: 4,
    },
    {
      name: 'Bitcoin',
      symbol: 'BTC',
      address: '0xEA034fb02eB1808C2cc3adbC15f447B93CbE08e1',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/bitcoin',
      decimals: 8,
      isShortable: true,
      displayDecimals: 2,
      rewardDisplayDecimals: 4,
    },
    {
      name: 'Matic',
      symbol: 'MATIC',
      decimals: 18,
      address: '0xa2036f0538221a77A3937F1379699f44945018d0',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/polygon',
      isShortable: true,
      displayDecimals: 4,
      rewardDisplayDecimals: 2,
    },
    {
      name: 'USDC',
      symbol: 'USDC',
      address: '0xA8CE8aee21bC2A48a5EF670afCc9274C7bbbC035',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/usd-coin',
      decimals: 6,
      isStable: true,
      displayDecimals: 4,
      rewardDisplayDecimals: 2,
    },
    {
      name: 'USDT',
      symbol: 'USDT',
      address: '0x1E4a5963aBFD975d8c9021ce480b42188849D41d',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/tether',
      decimals: 6,
      isStable: true,
      displayDecimals: 4,
      rewardDisplayDecimals: 2,
    },
    {
      name: 'Dai',
      symbol: 'DAI',
      address: '0xC5015b9d9161Dca7e18e32f6f25C4aD850731Fd4',
      coingeckoUrl: 'https://www.coingecko.com/en/coins/dai',
      decimals: 18,
      isStable: true,
      displayDecimals: 4,
      rewardDisplayDecimals: 2,
    },
  ],
}

const TOKENS_BY_SYMBOL_MAP = {}

TOKENS[1101].forEach((token) => {
  const { symbol } = token || {}
  TOKENS_BY_SYMBOL_MAP[symbol] = token
})

export function getTokenBySymbol(symbol: string) {
  const token = TOKENS_BY_SYMBOL_MAP[symbol]
  if (!token) {
    throw new Error(`Incorrect symbol "${symbol}"`)
  }
  return token
}
