import styled from 'styled-components'

export const MobileTableWrapper = styled.div`
  border: solid 1px;
  border-color: ${({ theme }) => theme.deprecated_bg1};

  border-top: none;
  width: 100%;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;

  max-height: 400px;
  overflow-y: auto;
`

export const PositionMRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 100%;
  gap: 10px;

  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.deprecated_bg1};
  }

  &:nth-child(even) {
    background-color: ${({ theme }) => theme.deprecated_bg2};
  }
`

export const MetricsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
`

export const Metric = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  color: #d1d2e6;
  font-size: 14px;
  min-width: 120px;
  /* flex: 0 1 20%; */
`

export const MetricTitle = styled.div`
  color: #d1d2e6;
  font-size: 14px;
`

export const MetricValue = styled.div`
  color: #f2f2f8;
  font-size: 14px;
`

export const PoisitionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CloseButton = styled.button`
  padding: 6px 6px;
  color: #c8cffd;
  border: solid 1px #c8cffd;
  border-radius: 0.5rem;
  background-color: transparent;
  font-size: 12px;
  transition: all 300ms;
  &:hover {
    cursor: pointer;
    border-color: ${({ theme }) => theme.deprecated_primary1};
    color: ${({ theme }) => theme.deprecated_primary1};
  }
`

export const CloseButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
`

export const RewardItem = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
  color: #f2f2f8;
  font-size: 14px;
`

export const RewardsRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`

export const EmptyState = styled.div`
  background-color: #17182b;
  padding: 24px 0;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 100%;
  text-align: center;

  border: solid 1px;
  border-top: none;
  border-color: ${({ theme }) => theme.deprecated_bg1};
`
