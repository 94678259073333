import { Currency, CurrencyAmount } from '@uniswap/sdk-core'
import JSBI from 'jsbi'

const MIN_NATIVE_CURRENCY_FOR_GAS: JSBI = JSBI.exponentiate(JSBI.BigInt(10), JSBI.BigInt(16)) // .01 ETH
/**
 * Given some token amount, return the max that can be spent of it
 * @param currencyAmount to return max of
 */
export function maxAmountSpend(
  currencyAmount?: CurrencyAmount<Currency> | null,
  gasPrice?: any
): CurrencyAmount<Currency> | undefined {
  if (!currencyAmount || !gasPrice) return undefined

  const gasAmount = JSBI.multiply(JSBI.multiply(JSBI.BigInt(200000), JSBI.BigInt(gasPrice.toString())), JSBI.BigInt(13))

  if (currencyAmount.currency.isNative) {
    if (JSBI.greaterThan(currencyAmount.quotient, gasAmount)) {
      return CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.subtract(currencyAmount.quotient, gasAmount))
    } else {
      return CurrencyAmount.fromRawAmount(currencyAmount.currency, JSBI.BigInt(0))
    }
  }
  return currencyAmount
}
