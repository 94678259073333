import { useState } from 'react'
import styled from 'styled-components'

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  &:hover {
    color: #665eed;
    transition: all 150ms;
    cursor: pointer;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const DDWrapper = styled.div`
  padding: 20px;
  background-color: #17182b;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 10px;
`

const Padder = styled.div`
  position: absolute;
  padding-top: 10px;
  top: 18px;
  left: 0px;
`
const DDLink = styled.a`
  text-decoration: none;
  color: white;
  font-weight: 500;
  &:hover {
    color: #665eed;
    transition: all 150ms;
    cursor: pointer;
  }
`

const ProductDropdown = () => {
  const [showDD, setShowDD] = useState(false)
  return (
    <Wrapper onMouseEnter={() => setShowDD(true)} onMouseLeave={() => setShowDD(false)}>
      <Link>Product</Link>
      {showDD && (
        <Padder>
          <DDWrapper>
            <DDLink href="https://drive.google.com/drive/folders/1QaeU-LqLs_EXLYlwT-C4w1neyrYUkQQ2" target="_blank">
              Media
            </DDLink>
            <DDLink
              href="https://assets.website-files.com/622a09bc809cd190f58b7b15/62bc2790e0fa1d51aef47a93_Market_Risk_OVIX.pdf"
              target="_blank"
            >
              Research
            </DDLink>
            <DDLink href="https://docs.keom.io/keom-protocol-documentation/" target="_blank">
              Documentation
            </DDLink>
          </DDWrapper>
        </Padder>
      )}
    </Wrapper>
  )
}

export default ProductDropdown
