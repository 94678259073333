
// desktop widths

export const APR_CELL_WIDTH = '80px'

export const TOTAL_CELL_WIDTH = '80px'

export const SELECT_CELL_WIDTH = '50px'

export const WALLET_CELL_WIDTH = '100px'

export const ASSET_CELL_WIDTH = '120px'

export const BORROW_CELL_WIDTH = '120px'

export const BORROW_APR_CELL_WIDTH = '120px'

export const LIQUIDITY_CELL_WIDTH = '80px'

export const DEPOSIT_CELL_WIDTH = '100px'

export const COLLATERAL_SWITCH_CELL_WIDTH = '90px'


// mobile widths

export const APR_CELL_WIDTH_MOBILE = '30px'

export const TOTAL_CELL_WIDTH_MOBILE = '50px'

export const SELECT_CELL_WIDTH_MOBILE = '50px'

export const WALLET_CELL_WIDTH_MOBILE = '80px'

export const ASSET_CELL_WIDTH_MOBILE = '80px'

export const BORROW_CELL_WIDTH_MOBILE = '40px'

export const BORROW_APR_CELL_WIDTH_MOBILE = '30px'

export const LIQUIDITY_CELL_WIDTH_MOBILE = '60px'

export const DEPOSIT_CELL_WIDTH_MOBILE = '70px'

export const COLLATERAL_SWITCH_CELL_WIDTH_MOBILE = '100px'


// desktop widths - professional

export const PROFESSIONAL_APR_CELL_WIDTH = '80px'

export const PROFESSIONAL_TOTAL_CELL_WIDTH = '80px'

export const PROFESSIONAL_SELECT_CELL_WIDTH = '100px'

export const PROFESSIONAL_WALLET_CELL_WIDTH = '100px'

export const PROFESSIONAL_ASSET_CELL_WIDTH = '100px'

export const PROFESSIONAL_PRICE_CELL_WIDTH = '90px'

export const PROFESSIONAL_TIME_CELL_WIDTH = '100px'

export const PROFESSIONAL_LIQUIDITY_CELL_WIDTH = '80px'

export const PROFESSIONAL_POSITION_CELL_WIDTH = '100px'

export const PROFESSIONAL_POSITION_EXPANDED_CELL_WIDTH = '200px'

export const PROFESSIONAL_COLLATERAL_SWITCH_CELL_WIDTH = '90px'



// mobile widths

export const PROFESSIONAL_APR_CELL_WIDTH_MOBILE = '60px'

export const PROFESSIONAL_TOTAL_CELL_WIDTH_MOBILE = '50px'

export const PROFESSIONAL_SELECT_CELL_WIDTH_MOBILE = '50px'

export const PROFESSIONAL_WALLET_CELL_WIDTH_MOBILE = '80px'

export const PROFESSIONAL_ASSET_CELL_WIDTH_MOBILE = '80px'

export const PROFESSIONAL_PRICE_CELL_WIDTH_MOBILE = '70px'

export const PROFESSIONAL_TIME_CELL_WIDTH_MOBILE = '50px'

export const PROFESSIONAL_LIQUIDITY_CELL_WIDTH_MOBILE = '60px'

export const PROFESSIONAL_POSITION_CELL_WIDTH_MOBILE = '70px'

export const PROFESSIONAL_COLLATERAL_SWITCH_CELL_WIDTH_MOBILE = '100px'