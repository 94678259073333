import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useGetNativeBalance } from 'lib/hooks/useCurrencyBalance'
import { Text } from 'rebass'
import { useDarkModeManager } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { ReactComponent as LogoDark } from '../../assets/svg/keom-logo.svg'
import Web3Status from '../Web3Status'
import NetworkSelector from './NetworkSelector'
import Row from 'components/Row'
import ProductDropdown from './ProductDropdown'

const HeaderFrame = styled.div<{ showBackground: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1600px;
  top: 0;
  position: relative;
  padding: 1rem;
  z-index: 21;
  position: relative;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(16px);

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding:  1rem;
    grid-template-columns: 36px 1fr;
  `};
`

const HeaderControls = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-self: flex-end;
`

const HeaderElement = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-child) {
    margin-left: 0.5em;
  }

  /* addresses safaris lack of support for "gap" */
  & > *:not(:first-child) {
    margin-left: 8px;
  }

  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    align-items: center;
  `};
`

const AccountElement = styled.div<{ active: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background-color: ${({ theme, active }) => (!active ? theme.deprecated_bg0 : theme.deprecated_bg0)}; */
  border-radius: 5px;
  white-space: nowrap;
  width: 100%;
`

const BalanceText = styled(Text)`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
    display: none;
  `};
`

const Title = styled.a`
  display: flex;
  align-items: center;
  pointer-events: auto;
  justify-self: flex-start;
  margin-right: 12px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    justify-self: center;
  `};
  :hover {
    cursor: pointer;
  }
`

const StyledIconAbs = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;
`

const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    display: none;
  `};
`

const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: white;
  font-weight: 500;
  &:hover {
    color: #665eed;
    transition: all 150ms;
    cursor: pointer;
  }
`

// can't be customized under react-router-dom v6
// so we have to persist to the default one, i.e., .active
export default function Header() {
  const { account } = useWeb3React()

  return (
    <HeaderFrame showBackground>
      <LogoWrapper>
        <LogoDark width="96px" height="100%" title="logo" />
        <LinksWrapper>
          <Link>Governance</Link>
          <ProductDropdown />
        </LinksWrapper>
      </LogoWrapper>

      <HeaderControls>
        <HeaderElement>
          <NetworkSelector />
        </HeaderElement>
        <HeaderElement>
          <AccountElement active={!!account}>
            {/* {account && userEthBalance ? (
              <BalanceText style={{ flexShrink: 0, userSelect: 'none' }} pl="0.75rem" pr=".4rem" fontWeight={500}>
                <Trans>
                  {userEthBalance?.toSignificant(3)} {userEthBalance.currency.symbol}
                </Trans>
              </BalanceText>
            ) : null} */}
            <Web3Status />
          </AccountElement>
        </HeaderElement>
      </HeaderControls>
    </HeaderFrame>
  )
}
