import { LendingProtocol } from "state/1delta/actions"

/**
 * List of all the networks supported by the Uniswap Interface
 */
export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GOERLI = 5,
  KOVAN = 42,

  ARBITRUM_ONE = 42161,
  ARBITRUM_RINKEBY = 421611,

  OPTIMISM = 10,
  OPTIMISM_GOERLI = 420,

  POLYGON = 137,
  POLYGON_MUMBAI = 80001,

  CELO = 42220,
  CELO_ALFAJORES = 44787,

  POLYGON_ZK_EVM = 1101
}

export const CHAIN_IDS_TO_NAMES = {
  [SupportedChainId.MAINNET]: 'mainnet',
  [SupportedChainId.ROPSTEN]: 'ropsten',
  [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  [SupportedChainId.KOVAN]: 'kovan',
  [SupportedChainId.POLYGON]: 'polygon',
  [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  [SupportedChainId.CELO]: 'celo',
  [SupportedChainId.CELO_ALFAJORES]: 'celo_alfajores',
  [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  [SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  [SupportedChainId.OPTIMISM]: 'optimism',
  [SupportedChainId.OPTIMISM_GOERLI]: 'optimism_goerli',
  [SupportedChainId.POLYGON_ZK_EVM]: 'polygon_zk_evm',
}

export const chainIds = [
  SupportedChainId.MAINNET,
  SupportedChainId.GOERLI,
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.POLYGON_ZK_EVM
]

/**
 * Array of all the supported chain IDs
 */
export const ALL_SUPPORTED_CHAIN_IDS: SupportedChainId[] = Object.values(SupportedChainId).filter(
  (id) => typeof id === 'number'
) as SupportedChainId[]

export function isSupportedChain(chainId: number | null | undefined): chainId is SupportedChainId {
  return chainIds.includes(Number(chainId)) // !!chainId && !!SupportedChainId[chainId]
}

export const SUPPORTED_GAS_ESTIMATE_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.POLYGON,
  SupportedChainId.CELO,
  SupportedChainId.OPTIMISM,
  SupportedChainId.ARBITRUM_ONE,
]

/**
 * Unsupported networks for V2 pool behavior.
 */
export const UNSUPPORTED_V2POOL_CHAIN_IDS = [
  SupportedChainId.POLYGON,
  SupportedChainId.OPTIMISM,
  SupportedChainId.ARBITRUM_ONE,
]

export const TESTNET_CHAIN_IDS = [
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.ARBITRUM_RINKEBY,
  SupportedChainId.OPTIMISM_GOERLI,
]

export type SupportedTestnetChainId = typeof TESTNET_CHAIN_IDS[number]

/**
 * All the chain IDs that are running the Ethereum protocol.
 */
export const L1_CHAIN_IDS = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.RINKEBY,
  SupportedChainId.GOERLI,
  SupportedChainId.KOVAN,
  SupportedChainId.POLYGON,
  SupportedChainId.POLYGON_MUMBAI,
  SupportedChainId.CELO,
  SupportedChainId.CELO_ALFAJORES,
  SupportedChainId.POLYGON_ZK_EVM,
] as const

export type SupportedL1ChainId = typeof L1_CHAIN_IDS[number]

/**
 * Controls some L2 specific behavior, e.g. slippage tolerance, special UI behavior.
 * The expectation is that all of these networks have immediate transaction confirmation.
 */
export const L2_CHAIN_IDS = [
  SupportedChainId.ARBITRUM_ONE,
  SupportedChainId.ARBITRUM_RINKEBY,
  SupportedChainId.OPTIMISM,
  SupportedChainId.OPTIMISM_GOERLI,
] as const

export type SupportedL2ChainId = typeof L2_CHAIN_IDS[number]

export const FALLBACK_CHAINID = SupportedChainId.GOERLI

export const DEFAULT_CHAINID = SupportedChainId.POLYGON_ZK_EVM

export const COMPOUND_CHAINIDS = [SupportedChainId.POLYGON, SupportedChainId.POLYGON_MUMBAI, SupportedChainId.GOERLI]

export const COMPOUNDV3_CHAINIDS = [SupportedChainId.POLYGON_MUMBAI]

export const AAVE_CHAINIDS = [SupportedChainId.GOERLI, SupportedChainId.POLYGON_MUMBAI, SupportedChainId.POLYGON]

export const COMPOUND_VIEW_CHAINIDS = [SupportedChainId.POLYGON, SupportedChainId.POLYGON_MUMBAI, SupportedChainId.GOERLI, SupportedChainId.MAINNET]

export const AAVE_VIEW_CHAINIDS = [SupportedChainId.GOERLI, SupportedChainId.POLYGON, SupportedChainId.POLYGON_MUMBAI]

export const AAVE_ORACLE_PREFERENCE = [
  SupportedChainId.POLYGON
]

export const PREFERRED_LENDERS: { [chainId: number]: string } = {
  [SupportedChainId.GOERLI]: LendingProtocol.AAVE,
  [SupportedChainId.MAINNET]: LendingProtocol.COMPOUND,
  [SupportedChainId.POLYGON]: LendingProtocol.AAVE,
  [SupportedChainId.POLYGON_MUMBAI]: LendingProtocol.AAVE
}
