import React, { useCallback, useEffect, useState } from 'react'
import { ChevronDown, ChevronUp } from 'react-feather'
import { LendingProtocol } from 'state/1delta/actions'
import { useIsUserLoaded } from 'state/1delta/hooks'
import { useChainIdAndAccount } from 'state/globalNetwork/hooks'
import { useAppDispatch } from 'state/hooks'
import { setFilterDebt } from 'state/tableFilter/actions'
import { useDebtFilterHandlers, useDebtFilterList, useDebtFilterSetting } from 'state/tableFilter/hooks'
import styled from 'styled-components/macro'
import { Filter, FilterActive } from 'utils/tableUtils/filters'
import PositionRow from './PositionRow'
import {
  AssetHeaderPro,
  TimeHeaderPro,
  TableContainerPro,
  TableHeaderPro,
  TableHeaderRowPro,
  PnLHeaderPro,
  PriceHeaderPro,
  CheckboxHeaderPro,
  PositionHeaderPro,
  HeaderItem,
} from 'components/Styles/tableStylesProfessional'
import { AaveInterestMode, SupportedAssets } from 'types/1delta'
import { ExtendedSlot } from 'state/slots/hooks'
import { PNL_FLAG_ON } from './config'
import { BaseButton } from 'components/Button'
import { EmptyState, MobileTableWrapper, PositionMRow } from 'components/Styles/mobileTable'
import PositionRowMobile from './PositionRowMobile'
import { useIsDesktop } from 'hooks/useIsMobile'
import { MouseoverTooltip } from 'components/Tooltip'

const Table = styled.table`
  border-collapse: collapse;
  border-spacing: 10px;
  width: 100%;
  border: 1px solid;

  border-top: none;
  border-color: ${({ theme }) => theme.backgroundInteractive};
`

const ChevronContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 10px;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
  width: 10px;
`};
`

const SimpleRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`

const StyledText = styled.div`
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToExtraSmall`
  text-align: center;  
  `};
`

const ConnectText = styled.div`
  width: 100%;
  padding: 10px;
  text-align: center;
  font-size: 14px;
  color: ${({ theme }) => theme.textPrimary};
`

const ButtonRow = styled.div`
  padding: 8px;
  border-radius: 0.75rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  background: #0c0f12;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border: 1px solid;
  border-bottom: none;
  background: ${({ theme }) => theme.deprecated_bg2};
  border-color: ${({ theme }) => theme.deprecated_bg1};
  padding-bottom: 20px;
`

export const ButtonLightBoring = styled(BaseButton)`
  color: ${({ theme }) => theme.deprecated_primaryText1};
  font-size: '16px';
  font-weight: '500';

  :disabled {
    opacity: 0.4;
    :hover {
      cursor: auto;
      background-color: ${({ theme }) => theme.deprecated_primary5};
      box-shadow: none;
      outline: none;
    }
  }
`

const TypeButton = styled(ButtonLightBoring)<{ selected: boolean }>`
  padding: 0px;
  border-radius: 8px;
  font-size: 14px;
  width: 140px;
  background: none;
  height: 40px;
`

const GradientText = styled.span`
  background: linear-gradient(47.88deg, #bfa6fb 0.94%, #6f4cc9 25.53%, #7574f7 46.61%, #9ad2f8 65.05%, #5d8dc0 88.77%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

const ModeSelectionCard = styled.div<{ selected: boolean }>`
  border-radius: 10px;
  width: 140px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: none;
  border-radius: 0.75rem;
  ${({ theme, selected }) =>
    selected
      ? `
    background-color: ${theme.deprecated_bg1};
    font-weight: bold;
    `
      : `
    opacity: 0.5;
    background-color: transparent;
    `}
`

const HeaderText = styled.div`
  font-size: 16px;
  font-weight: 600;
`

const TableBody = styled.tbody`
  max-height: 200px;
  overflow-y: auto;
`

export interface MappedSwapAmounts {
  [asset: string]: { amount: number; type: AaveInterestMode }
}

interface PositionTableProps {
  isMobile: boolean
  assetData: ExtendedSlot[]
  setShowCloseModal: () => void
  setSelectedSlot: (slot: ExtendedSlot) => void
  onSelect?: (option: [SupportedAssets, SupportedAssets]) => void
}

export default function PositionTable({
  assetData,
  isMobile,
  setShowCloseModal,
  setSelectedSlot,
  onSelect,
}: PositionTableProps) {
  const { account, chainId } = useChainIdAndAccount()

  const [show, setShowData] = useState(false)

  const isDesktop = useIsDesktop()

  const setShow = useCallback(() => {
    setShowData(!show)
  }, [show])

  const lendingProtocol = LendingProtocol.COMPOUND
  const userLoaded = useIsUserLoaded(lendingProtocol)
  const { handleAprFilter, handleStableAprFilter, handleLiquidityFilter, handleUserBorrowFilter, handleUnInit } =
    useDebtFilterHandlers(lendingProtocol)

  const relevantAccount = account

  // initializes filter when account data loaded and account switches
  const [initialize, setInitialize] = useState(false)

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!initialize && userLoaded && assetData.map((d) => d.price).every((b) => b > 0) && relevantAccount) {
      dispatch(setFilterDebt({ protocol: lendingProtocol, filter: FilterActive.USER, filterState: Filter.DESC }))
      setInitialize(true)
    }
  }, [relevantAccount, lendingProtocol, userLoaded, initialize])

  // useInitializeDebtFilter(lendingProtocol, assetData.map(x => x.assetId))

  // if account changes we re-init
  useEffect(() => {
    if (relevantAccount) {
      handleUnInit()
      setInitialize(false)
    }
  }, [lendingProtocol, relevantAccount])

  const filterStateChevrons = useDebtFilterSetting(lendingProtocol)
  const [viewPositions, setViewPositions] = useState(true)
  // handles change to sorted items
  // useHandleDebtFilter(lendingProtocol, assetData)

  const dataToShow = viewPositions
    ? assetData.filter((d) => d.closeTime === 0)
    : assetData.filter((d) => d.closeTime > 0)

  const getComponent = () => {
    if (!account) {
      return (
        <EmptyState>
          <span style={{ fontWeight: 'bold' }}>Connect your wallet to see your positions</span>
        </EmptyState>
      )
    } else if (dataToShow.length > 0) {
      return (
        <>
          {isDesktop ? (
            <Table>
              <TableHeaderPro>
                <TableHeaderRowPro>
                  <HeaderItem>
                    <StyledText>Symbol</StyledText>
                  </HeaderItem>
                  <HeaderItem
                    style={{
                      minWidth: 60,
                    }}
                  >
                    PnL
                  </HeaderItem>
                  <HeaderItem>Size</HeaderItem>
                  <HeaderItem>Entry Price</HeaderItem>
                  <HeaderItem>{viewPositions ? 'Market Price' : 'Exit Price'}</HeaderItem>
                  {viewPositions && <HeaderItem>Liq. Price</HeaderItem>}
                  {viewPositions && (
                    <MouseoverTooltip
                      text={
                        <span
                          style={{
                            fontSize: 14,
                          }}
                        >
                          This value is calculated using: <br />
                          <span
                            style={{
                              fontSize: 12,
                              color: '#665EED',
                              fontWeight: 600,
                            }}
                          >
                            {'Leverage * (Collateral APR + Debt APR) - Debt APR'}
                          </span>
                        </span>
                      }
                    >
                      <HeaderItem>APR</HeaderItem>
                    </MouseoverTooltip>
                  )}
                  <HeaderItem>Time</HeaderItem>
                  <HeaderItem></HeaderItem>
                </TableHeaderRowPro>
              </TableHeaderPro>
              <TableBody>
                {dataToShow.map((dat, i) => (
                  <PositionRow
                    onSelect={onSelect}
                    isClosed={dat.closeTime > 0}
                    index={i}
                    isMobile={isMobile}
                    {...dat}
                    key={i}
                    selectSlot={() => {
                      setSelectedSlot(dat)
                      setShowCloseModal()
                    }}
                    topSep={false}
                  />
                ))}
              </TableBody>
            </Table>
          ) : (
            <MobileTableWrapper>
              {dataToShow.map((dat, i) => (
                <PositionRowMobile
                  index={i}
                  isMobile={isMobile}
                  {...dat}
                  key={i}
                  selectSlot={() => {
                    setSelectedSlot(dat)
                    setShowCloseModal()
                  }}
                  topSep={false}
                />
              ))}
            </MobileTableWrapper>
          )}
        </>
      )
    } else {
      return (
        <EmptyState>
          <span>
            You don’t have any {viewPositions ? 'open' : 'closed'} positions.{' '}
            <span style={{ fontWeight: 'bold' }}>
              Kickstart your earnings by <GradientText>opening a position.</GradientText>
            </span>
          </span>
        </EmptyState>
      )
    }
  }

  return (
    <TableContainerPro>
      <ButtonRow>
        <TypeButton onClick={() => !viewPositions && setViewPositions(true)} selected={viewPositions}>
          <ModeSelectionCard selected={viewPositions}>
            <HeaderText>Positions</HeaderText>
          </ModeSelectionCard>
        </TypeButton>
        <TypeButton onClick={() => viewPositions && setViewPositions(false)} selected={!viewPositions}>
          <ModeSelectionCard selected={!viewPositions}>
            <HeaderText>History</HeaderText>
          </ModeSelectionCard>
        </TypeButton>
      </ButtonRow>
      {getComponent()}
    </TableContainerPro>
  )
}

const TablRowPro = styled.tr`
  display: flex;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.deprecated_bg2};
  &:last-child {
    padding-right: -10px;
  }
  &:first-child {
  }
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
  font-size: 12px;
  height: 25px;
  `};
  border-bottom: none;
`
const SeparatorCell = styled.td`
  width: 100%;
`

const HistoryHeader = styled.div`
  border-top: 1px solid ${({ theme }) => theme.backgroundOutline};
  border-bottom: none;
  color: ${({ theme }) => theme.textSecondary};
  font-weight: 400;
  text-align: left;
  padding: 10px;
`
