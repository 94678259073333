import { RouteV3 } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent } from '@uniswap/sdk-core'
import { DeltaSlot, SlotManager } from 'abis/types'
import { ethers } from 'ethers'
import { ContractCallDataWithOptions, ContractCallWithOptions, UniswapTrade } from '../../Types'
import { TradeAction } from 'pages/Trading'
import { FeeAmount } from 'types/1delta'
import { ADDRESS_ZERO } from '@uniswap/v3-sdk'

const typeSliceSimple = ['address', 'uint8']
const typeSliceAggregator = ['address', 'uint24', 'uint8', 'uint8']

export function encodeAlgebraPathEthersSimple(path: string[], flags: number[], flag: number): string {
  if (path.length != flags.length + 1) {
    throw new Error('path/fee lengths do not match')
  }
  let types: string[] = []
  let data: string[] = []
  for (let i = 0; i < flags.length; i++) {
    const p = path[i]
    types = [...types, ...typeSliceSimple]
    data = [...data, p, String(flags[i])]
  }
  // add last address and flag
  types.push('address')
  types.push('uint8')
  data.push(path[path.length - 1])
  data.push(String(flag))
  // console.log(data)
  // console.log(types)
  return ethers.utils.solidityPack(types, data)
}

export function encodeAggregtorPathEthers(
  path: string[],
  fees: FeeAmount[],
  flags: number[],
  pIds: number[],
  flag: number
): string {
  if (path.length != fees.length + 1) {
    throw new Error('path/fee lengths do not match')
  }
  let types: string[] = []
  let data: string[] = []
  for (let i = 0; i < fees.length; i++) {
    const p = path[i]
    types = [...types, ...typeSliceAggregator]
    data = [...data, p, String(fees[i]), String(pIds[i]), String(flags[i])]
  }
  // add last address and flag
  types.push('address')
  types.push('uint8')

  data.push(path[path.length - 1])
  data.push(String(flag))

  return ethers.utils.solidityPack(types, data)
}

export function encodeAddress(path: string): string {
  return ethers.utils.solidityPack(['address'], [path])
}

export const createSlotManagerCalldata = (
  inIsNative: boolean,
  action: TradeAction,
  parsedAmountIn: CurrencyAmount<Currency> | undefined,
  tradeIn: UniswapTrade | undefined,
  trade: UniswapTrade | undefined,
  allowedSlippage: Percent,
  slotManagerContract: SlotManager,
  account?: string,
  permit?: any,
  slotAddress?: string
): ContractCallDataWithOptions => {
  let args: any = {}
  let method: any
  let estimate: any
  let contractCall: ContractCallWithOptions | undefined = undefined
  const v3Route = trade?.routes[0] as RouteV3<Currency, Currency>
  const tradeType = trade?.tradeType

  if (!trade || !v3Route || tradeType === undefined || !account)
    return {
      args: {},
      method: undefined,
      estimate: undefined,
    }

  const v3RouteIn = tradeIn?.routes[0] as RouteV3<Currency, Currency>

  if (action === TradeAction.OPEN) {
    const pathIn = tradeIn
      ? encodeAggregtorPathEthers(
        v3RouteIn.path.map((p) => p.address),
        new Array(v3RouteIn.path.length - 1).fill(FeeAmount.MEDIUM),
        new Array(v3RouteIn.path.length - 1).fill(3),
        new Array(v3RouteIn.path.length - 1).fill(0),
        0
      )
      : parsedAmountIn
        ? encodeAddress(parsedAmountIn.currency.wrapped.address)
        : '0x' // - should fail

    const pathMargin = trade
      ? encodeAggregtorPathEthers(
        v3Route.path.map((p) => p.address),
        new Array(v3Route.path.length - 1).fill(FeeAmount.MEDIUM),
        v3Route.path.length === 1 ? [0] : [0, ...new Array(v3Route.path.length - 2).fill(3)],
        new Array(v3Route.path.length - 1).fill(0),
        0
      )
      : '0x' // - should fail

    const amountDeposited = tradeIn ? tradeIn.inputAmount.quotient.toString() : parsedAmountIn?.quotient.toString()

    args = {
      amountDeposited,
      minimumAmountDeposited: tradeIn?.minimumAmountOut(allowedSlippage).quotient.toString() ?? '0',
      borrowAmount: trade.inputAmount.quotient.toString(),
      minimumMarginReceived: trade.minimumAmountOut(allowedSlippage).quotient.toString(),
      // path to deposit - can be empty if depo ccy = collateral
      swapPath: pathIn,
      // path for margin trade
      marginPath: pathMargin,
      fee: '0',
      partner: ADDRESS_ZERO,
    }

    if (permit) {
      const { signature, deadline } = permit
      const { v, r, s } = signature || {}
      const permitData = [account, slotAddress, amountDeposited, deadline, v, r, s]

      args.permit = permitData
    }

    method = undefined

    const value = inIsNative ? args.amountDeposited : undefined

    estimate = async (opts: any) => {
      if (permit) {
        return slotManagerContract.estimateGas.createSlotWithPermit(args, { ...opts, value })
      }
      return slotManagerContract.estimateGas.createSlot(args, { ...opts, value })
    }
    contractCall = async (opts: any) => {
      if (permit) {
        return slotManagerContract.createSlotWithPermit(args, { ...opts, value })
      }
      return slotManagerContract.createSlot(args, { ...opts, value })
    }
  } else {
  }
  return { args, method, estimate, call: contractCall }
}

export const createSlotCalldata = (
  action: TradeAction,
  amountToRepay: CurrencyAmount<Currency> | undefined,
  trade: UniswapTrade | undefined,
  allowedSlippage: Percent,
  slotManager: SlotManager,
  slotAddress: string,
  account?: string
): ContractCallDataWithOptions => {
  let args: any = {}
  let method: any
  let estimate: any
  let contractCall: ContractCallWithOptions | undefined = undefined
  const v3Route = trade?.routes[0] as RouteV3<Currency, Currency>
  const tradeType = trade?.tradeType

  if (!trade || !v3Route || tradeType === undefined || !account)
    return {
      args: {},
      method: undefined,
      estimate: undefined,
    }

  if (action === TradeAction.CLOSE) {
    const amountRepay = 0 // repay all
    const pathMargin = trade
      ? encodeAggregtorPathEthers(
        [...v3Route.path].reverse().map((p) => p.address),
        new Array(v3Route.path.length - 1).fill(FeeAmount.MEDIUM),
        v3Route.path.length === 1 ? [0] : [1, ...new Array(v3Route.path.length - 2).fill(2)],
        new Array(v3Route.path.length - 1).fill(0),
        0
      )
      : '0x' // - should fail

    const maxIn = trade.maximumAmountIn(allowedSlippage).quotient.toString()
    method = undefined
    estimate = async () => await slotManager.estimateGas.closeSlot(slotAddress, amountRepay, maxIn, pathMargin)
    contractCall = async (opts: any) => await slotManager.closeSlot(slotAddress, amountRepay, maxIn, pathMargin, opts)

    args = {
      amountRepay,
      maxIn,
      pathMargin,
    }
  } else {
  }
  return { args, method, estimate, call: contractCall }
}
