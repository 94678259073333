import Loader from 'components/Loader'
import { Suspense, lazy, useEffect, useState } from 'react'
import { Route, Routes, useLocation, BrowserRouter } from 'react-router-dom'
import { useIsDarkMode } from 'state/user/hooks'
import styled from 'styled-components/macro'
import { Z_INDEX } from 'theme/zIndex'

import Professional from './Trading'
import { useAnalyticsReporter } from '../components/analytics'
import ErrorBoundary from '../components/ErrorBoundary'
import Header from '../components/Header'
import Polling from '../components/Header/Polling'
import Popups from '../components/Popups'
import DarkModeQueryParamReader from '../theme/DarkModeQueryParamReader'
import { ButtonPrimary } from 'components/Button'
import Footer from 'components/Footer'

// const Professional = lazy(() => import('./Professional'))
// const Home = lazy(() => import('./1delta'))

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  font-feature-settings: "'ss01' on, 'ss02' on, 'cv01' on, 'cv03' on";
`

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 100px 0px 0px 0px;
  align-items: center;
  flex: 1;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToSmall`
    padding: 52px 0px 16px 0px;
  `};
`

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexRowNoWrap}
  width: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: ${Z_INDEX.sticky};
  border-bottom: solid 1px #242642;
`

const Marginer = styled.div`
  margin-top: 5rem;
`

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 12px;
  background: ${({ theme }) => theme.deprecated_bg2};
  border: 1px solid;
  border-color: ${({ theme }) => theme.deprecated_bg1};
  margin: 200px auto 0 auto;
`

const Input = styled.input`
  background: ${({ theme }) => theme.deprecated_bg1};
  font-size: 16px;
  outline: none;
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
  color: ${({ theme, color }) => (color === 'red' ? theme.deprecated_red1 : theme.deprecated_text1)};
  width: 100%;
  box-shadow: none;
  border: none;
  border-radius: 8px;
  padding: 8px;
`

const LOCK_PASSWORD = 'keom-margin'

export default function App() {
  const { pathname } = useLocation()
  const [locked, setLocked] = useState(true)
  const [password, setPassword] = useState('')

  useAnalyticsReporter()

  useEffect(() => {
    window.scrollTo(0, 0)

    const password = localStorage.getItem('password')
    if (password === LOCK_PASSWORD) {
      setLocked(false)
    }
  }, [pathname])

  const submit = () => {
    if (password === LOCK_PASSWORD) {
      localStorage.setItem('password', password)
      setLocked(false)
    }
  }

  return (
    <ErrorBoundary>
      <DarkModeQueryParamReader />
      <AppWrapper>
        <HeaderWrapper>
          <Header />
        </HeaderWrapper>
        {locked ? (
          <InputWrapper>
            <p
              style={{
                margin: 0,
              }}
            >
              Please enter the password to access the product
            </p>
            <Input onChange={(e) => setPassword(e.target.value)} placeholder="Password goes here..." />
            <ButtonPrimary
              onClick={submit}
              style={{
                padding: 12,
              }}
            >
              Submit
            </ButtonPrimary>
          </InputWrapper>
        ) : (
          <BodyWrapper>
            <Popups />
            <Polling />
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route path="/" element={<Professional />} />
              </Routes>
            </Suspense>
            <Marginer />
          </BodyWrapper>
        )}
      </AppWrapper>
      <Footer />
    </ErrorBoundary>
  )
}
