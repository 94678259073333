import { getSupportedAssets, TEN } from 'constants/1delta'
import { SupportedChainId } from 'constants/chains'
import { BigNumber } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { Mode } from 'pages/Trading'
import { AppState } from 'state'
import { LendingProtocol } from 'state/1delta/actions'
import { useDeltaAssetState } from 'state/1delta/hooks'
import { useAppSelector } from 'state/hooks'
import { usePrices } from 'state/oracles/hooks'
import { SupportedAssets } from 'types/1delta'
import { calculateRateToNumber, TimeScale } from 'utils/tableUtils/format'
import { Slot } from './reducer'
import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'
import useHistoricPrices from './useHistoricPrices'

export function useSlotState(): AppState['slots'] {
  return useAppSelector((state) => state.slots)
}

export interface ExtendedSlot extends Slot {
  collateralBalanceUsd: number
  debtBalanceUsd: number
  collateralFactor: number
  healthFactor: number
  liquidationPrice: number
  pair: [SupportedAssets, SupportedAssets]
  leverage: number
  size: number
  rewardApr: number
  supplyApr: number
  borrowApr: number
  pnl: number
  direction: Mode
  price: number
  entryPrice: number
  originalSize: number
  netYield: number
}

const getFetchData = (slotAddresses: string[]) => {
  slotAddresses = slotAddresses.map((s) => `"${s}"`)

  return gql`
  {
    redeemEvents(where: { from_in: [${slotAddresses}] }) {
      amount
      blockNumber
      blockTime
      oTokenSymbol
      underlyingAmount
      from
    },

    repayEvents(where: { borrower_in: [${slotAddresses}] }) {
      amount
      blockNumber
      blockTime
      underlyingSymbol
      borrower
    }
  }
`
}

export const useParsedSlots = (chainId?: number, account?: string): ExtendedSlot[] => {
  const slotData = useSlotState()

  const queryString = useMemo(() => {
    return getFetchData((slotData?.slots || [])?.filter?.((s) => s.closeTime > 0).map((s) => s.slot) || [])
  }, [slotData])

  const { data: graphData } = useQuery(queryString)

  const blockNumbers = useMemo(() => {
    if (!graphData) return {}

    const result = {}
    graphData.repayEvents.forEach(({ underlyingSymbol, blockNumber }) => {
      result[blockNumber] = {
        ...result[blockNumber],
        debtSymbol: `o${underlyingSymbol === 'ETH' ? 'WETH' : underlyingSymbol}`,
      }
    })
    graphData.redeemEvents.forEach(({ blockNumber, oTokenSymbol, from }) => {
      result[blockNumber] = {
        ...result[blockNumber],
        collateralSymbol: oTokenSymbol,
        slot: from,
      }
    })

    return result
  }, [graphData])

  const { data: historicPrices } = useHistoricPrices(blockNumbers)

  const slotToAmounts = useMemo(() => {
    if (!graphData) return {}
    const result = {}
    graphData.repayEvents.forEach(({ amount, borrower }) => {
      result[borrower.toLowerCase()] = {
        ...result[borrower],
        d: Number(amount),
      }
    })
    graphData.redeemEvents.forEach(({ from, underlyingAmount }) => {
      result[from.toLowerCase()] = {
        ...result[from],
        c: Number(underlyingAmount),
      }
    })
    return result
  }, [graphData])

  const assets = getSupportedAssets(Number(chainId), LendingProtocol.COMPOUND)
  const assetData = useDeltaAssetState()
  const prices = usePrices(assets, SupportedChainId.POLYGON)
  const priceDict = Object.assign(
    {},
    ...assets.map((a, i) => {
      return { [a]: prices[i] }
    })
  )
  if (!account || !chainId) return []

  const cfs = Object.assign(
    {},
    ...assets.map((a) => {
      return {
        [a]: {
          cf: assetData[a].compoundData[chainId].reserveData.collateralFactorMantissa,
          cApr: assetData[a].compoundData[chainId].reserveData.supplyRatePerBlock,
          bApr: assetData[a].compoundData[chainId].reserveData.borrowRatePerBlock,
        },
      }
    })
  )

  return (slotData.slots || []).map((s) => {
    let c = Number(formatEther(BigNumber.from(s.collateralBalance ?? '0').mul(TEN.pow(18 - s.collateralDecimals))))
    let d = Number(formatEther(BigNumber.from(s.debtBalance ?? '0').mul(TEN.pow(18 - s.debtDecimals))))

    if (s.closeTime > 0 && graphData && historicPrices) {
      c = slotToAmounts?.[s.slot.toLowerCase()]?.c || '0'
      d = slotToAmounts?.[s.slot.toLowerCase()]?.d || '0'
    }

    const collateralAsset = safeSymbol(s.collateralSymbol as SupportedAssets)
    const debtAsset = safeSymbol(s.debtSymbol as SupportedAssets)
    const isClosed = s.closeTime > 0

    let cUSD = 0
    let dUSD = 0
    let exitPrice = 0
    if (s.closeTime > 0) {
      if (historicPrices) {
        cUSD = c * historicPrices[s.slot.toLowerCase()]?.cPrice || 0
        dUSD = d * historicPrices[s.slot.toLowerCase()]?.dPrice || 0

        exitPrice = Number(
          (collateralAsset.toUpperCase().includes('USDC')
            ? historicPrices[s.slot.toLowerCase()]?.dPrice
            : historicPrices[s.slot.toLowerCase()]?.cPrice) || '0'
        )
      }
    } else {
      cUSD = c * priceDict[collateralAsset ?? '']
      dUSD = d * priceDict[debtAsset ?? '']
    }

    const size = cUSD - dUSD
    const cf = Number(formatEther(cfs[collateralAsset]?.cf ?? '0'))
    const mode = collateralAsset.toUpperCase().includes('USDC') ? Mode.SHORT : Mode.LONG

    const collateralIn = Number(
      formatEther(BigNumber.from(s.collateralSwapped ?? '0').mul(TEN.pow(18 - s.collateralDecimals)))
    )
    const debtOut = Number(formatEther(BigNumber.from(s.debtSwapped ?? '0').mul(TEN.pow(18 - s.debtDecimals))))

    const cfPerYear =
      cUSD * calculateRateToNumber(cfs[collateralAsset]?.cApr ?? '0', chainId, TimeScale.MS) -
      dUSD * calculateRateToNumber(cfs[debtAsset]?.bApr ?? '0', chainId, TimeScale.MS)
    const netYield = cfPerYear / (cUSD - dUSD)

    const entryPrice = mode === Mode.LONG ? debtOut / collateralIn : collateralIn / debtOut

    const sizeMultiplier = isClosed ? historicPrices[s.slot.toLowerCase()]?.cPrice : priceDict[collateralAsset ?? '']
    const originalSize = (c - collateralIn) * sizeMultiplier

    return {
      ...s,
      collateralBalanceUsd: cUSD,
      debtBalanceUsd: dUSD,
      collateralFactor: cf,
      healthFactor: calculateHealthFactor(cf, cUSD, dUSD),
      liquidationPrice: calculateLiqPrice(cf, c, d, cUSD, dUSD, !collateralAsset.toUpperCase().includes('USDC')),
      pair:
        mode === Mode.LONG
          ? [collateralAsset as SupportedAssets, debtAsset as SupportedAssets]
          : [debtAsset as SupportedAssets, collateralAsset as SupportedAssets],
      leverage: mode === Mode.LONG ? cUSD / size : dUSD / size,
      size,
      rewardApr: 0.2,
      supplyApr: calculateRateToNumber(cfs[collateralAsset]?.cApr ?? '0', chainId, TimeScale.MS),
      borrowApr: calculateRateToNumber(cfs[debtAsset]?.bApr ?? '0', chainId, TimeScale.MS),
      direction: mode,
      pnl: originalSize === 0 ? 0 : (size - originalSize) / originalSize,
      price: mode === Mode.LONG ? priceDict[collateralAsset ?? ''] : priceDict[debtAsset ?? ''],
      entryPrice,
      originalSize,
      netYield,
      exitPrice,
    }
  })
}

const calculateHealthFactor = (cf: number, cUSD: number, dUSD: number) => {
  return (cf * cUSD) / dUSD
}

// hf = cUSD* cf / dUSD -> solve for hf = 1
export const calculateLiqPrice = (
  cf: number,
  c: number,
  d: number,
  cUSD: number,
  dUSD: number,
  collateral: boolean
) => {
  if (collateral) {
    return dUSD / c / cf
  }
  return (cUSD * cf) / d
}

const safeSymbol = (asset: SupportedAssets) => {
  if (asset === SupportedAssets.MATIC) return SupportedAssets.WMATIC
  return asset
}
