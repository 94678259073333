const api_root = 'https://benchmarks.pyth.network/v1/shims/tradingview/history'
const history = {}

const resolutionToPeriod = {
  '1D': '1d',
  '240': '4h',
  '60': '1h',
  '15': '15m',
  '5': '5m',
}

const historyProvider = {
  history,
  getBars: async (symbolInfo: any, resolution: any, periodParams: any) => {
    const split_symbol = symbolInfo.name.split(/[:/]/)

    const symbol = split_symbol[0]

    const urlParams = new URLSearchParams({
      resolution,
      from: periodParams.from,
      to: !periodParams.firstDataRequest ? periodParams.to : (Date.now() / 1000).toFixed(0),
      symbol: `Crypto.${symbol}/USD`,
    })

    let response: any = null
    try {
      const res = await fetch(`${api_root}?${urlParams}`)
      const { status } = res || {}

      const data = await res.json()

      const noOfBars = data.c.length

      const prices: any[] = []

      for (let i = 0; i < noOfBars; i++) {
        const { t, l, h, o, c } = data || {}
        const v = { t: t?.[i], l: l?.[i], h: h?.[i], o: o?.[i], c: c?.[i] }
        prices.push(v)
      }

      response = {
        status,
        data: { prices },
      }
    } catch (err) {
      console.log(err)
      return []
    }

    if (response.status && response.status.toString() !== '200') {
      console.log(' API error:', response.statusText)
      return []
    }
    if (response.data.prices.length) {
      const bars = response.data.prices.map((el: any) => {
        return {
          time: el.t * 1000, //TradingView requires bar time in ms
          low: el.l,
          high: el.h,
          open: el.o,
          close: el.c,
        }
      })
      if (periodParams.firstDataRequest) {
        const lastBar = bars[bars.length - 1]
        history[symbolInfo.name] = { lastBar }
      }
      return bars
    } else {
      return []
    }
  },
}

export default historyProvider
