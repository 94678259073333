import gql from 'graphql-tag'
import { useQuery } from '@apollo/client'
import { useMemo } from 'react'

const getSingleMarketString = (blockNumber: string) => {
  return `markets(block: {number: ${blockNumber}}){
    blockTimestamp,
    symbol,
    underlyingPrice,
    underlyingSymbol,
    underlyingPriceUSD,
    id
  },`
}

const generateQueryString = (blockNumbers: string[]) => {
  const queryString = `{
    dummy:markets(block: {number: 100}){
      id
    },
          ${blockNumbers.map(
    (n) => `
              b${n}: ${getSingleMarketString(n)}
              `
  )}
    
  }`
  return gql`
    ${queryString}
  `
}

const useHistoricPrices = (blocks: any) => {
  const queryString = useMemo(() => {
    return generateQueryString(Object.keys(blocks))
  }, [blocks])
  const { data: gdata } = useQuery(queryString)

  return useMemo(() => {
    const data = {}
    Object.keys(blocks).forEach((blockNumber: string) => {
      const thisBlockData = gdata?.[`b${blockNumber}`]
      const cPrice =
        thisBlockData?.find((m: any) => m?.symbol === blocks[blockNumber]?.collateralSymbol)?.underlyingPriceUSD || 0
      const dPrice =
        thisBlockData?.find((m: any) => m?.symbol === blocks[blockNumber]?.debtSymbol)?.underlyingPriceUSD || 0
      data[blocks[blockNumber]?.slot] = {
        cPrice,
        dPrice,
      }
    })
    return { data }
  }, [gdata, blocks])
}

export default useHistoricPrices
