import { getPythProgramKeyForCluster, getPythClusterApiUrl, PythHttpClient } from '@pythnetwork/client'
import { Connection } from '@solana/web3.js'
import historyProvider from './historyProvider'

// Websocket creation
connect()

// keep track of subscriptions
const _subs: any[] = []

// Take a single trade, and subscription record, return updated bar
function updateBar(data: any, sub: any) {
  const lastBar = sub.lastBar
  let resolution = sub.resolution
  if (resolution.includes('D')) {
    // 1 day in minutes === 1440
    resolution = 1440
  } else if (resolution.includes('W')) {
    // 1 week in minutes === 10080
    resolution = 10080
  }
  const coeff = resolution * 60
  const rounded = Math.floor(data.ts / coeff) * coeff
  const lastBarSec = lastBar.time / 1000
  let _lastBar

  if (rounded > lastBarSec) {
    // create a new candle, use last close as open **PERSONAL CHOICE**
    _lastBar = {
      time: rounded * 1000,
      open: lastBar.close,
      high: lastBar.close,
      low: lastBar.close,
      close: data.price,
      volume: data.volume,
    }
  } else {
    // update lastBar candle!
    if (data.price < lastBar.low) {
      lastBar.low = data.price
    } else if (data.price > lastBar.high) {
      lastBar.high = data.price
    }

    lastBar.volume += data.volume
    lastBar.close = data.price
    _lastBar = lastBar
  }
  return _lastBar
}

function subscribeBars(symbolInfo: any, resolution: any, updateCb: any, uid: any, resetCache: any) {
  // console.log("subscribeBars");
  const resolutionInMs = resolution === '1D' ? 60 * 60 * 24 * 1000 : resolution * 60 * 1000
  const newSub: any = {
    uid,
    resolution,
    symbolInfo,
    lastBar: historyProvider.history[symbolInfo.name].lastBar,
    listener: updateCb,
  }

  // reload chart from server in every resolution time
  newSub.reloadChartInterval = setInterval(() => {
    // console.log("SetInterval");

    const w = window as any
    w.tvWidget.load()
  }, resolutionInMs)

  _subs.push(newSub)
}

function unsubscribeBars(uid: any) {
  // console.log("unsubscribeBars");
  const chartSubcription = _subs.find((s) => s.uid === uid)
  clearInterval(chartSubcription.reloadChartInterval)
  const subIndex = _subs.findIndex((e) => e.uid === uid)
  if (subIndex === -1) {
    console.log('No subscription found for ', uid)
    return
  }
  _subs.splice(subIndex, 1)
}

function updateBarWithAveragePrice(name, averagePriceValue) {
  const chartSubcription = _subs.find((s) => s.symbolInfo.name === name)
  if (chartSubcription) {
    const barData = {
      ts: new Date().getTime() / 1000,
      price: averagePriceValue,
    }
    const _lastBar = updateBar(barData, chartSubcription)
    chartSubcription.listener(_lastBar)
    chartSubcription.lastBar = _lastBar
  }
}

async function connect() {
  const PYTHNET_CLUSTER_NAME = 'pythnet'
  const connection = new Connection(getPythClusterApiUrl(PYTHNET_CLUSTER_NAME))
  const pythPublicKey = getPythProgramKeyForCluster(PYTHNET_CLUSTER_NAME)
  try {
    const pythClient = new PythHttpClient(connection, pythPublicKey)

    setInterval(async () => {
      const data = await pythClient.getData()
      for (let i = 0; i < _subs.length; i++) {
        const chartSubcription = _subs[i]
        const tokenSymbol = chartSubcription.symbolInfo.name.split('/')[0]
        const pythSymbol = (symbol: string) => `Crypto.${symbol}/USD`
        const product = data.productPrice.get(pythSymbol(tokenSymbol)) as any
        const barData = {
          ts: product.timestamp.toString(),
          price: product.price,
        }
        const _lastBar = updateBar(barData, chartSubcription)
        chartSubcription.listener(_lastBar)
        chartSubcription.lastBar = _lastBar
      }
    }, 3000)
  } catch (error) {
    console.log('error', error)
  }
}

const stream = {
  updateBar,
  subscribeBars,
  unsubscribeBars,
  updateBarWithAveragePrice,
}

export default stream
