import { useMemo } from 'react'
import { Check } from 'react-feather'
import styled, { useTheme } from 'styled-components/macro'
import { CheckboxWrapper, Circle } from 'components/Styles/tableStyles'
import { PairPositionRow } from 'components/TokenDetail'
import { formatPriceString, formatSmallGeneralUSDValue, formatSmallUSDValue } from 'utils/tableUtils/format'
import { useChainIdAndAccount } from 'state/globalNetwork/hooks'
import { SupportedAssets } from 'types/1delta'
import { Mode } from 'pages/Trading'
import { SHOW_VIX_REWARDS, TOKEN_SVGS } from 'constants/1delta'
import { default as ovixStandalone } from 'assets/svg/logos/logo-0vix.svg'
import { ExternalLink as LinkIconFeather } from 'react-feather'
import { ExtendedSlot } from 'state/slots/hooks'
import { ExplorerDataType, getExplorerLink } from 'utils/getExplorerLink'
import { formatPercent } from 'utils/1delta/generalFormatters'
import {
  CloseButton,
  CloseButtonWrapper,
  Metric,
  MetricTitle,
  MetricValue,
  MetricsRow,
  PoisitionHeader,
  PositionMRow,
  RewardItem,
  RewardsRow,
} from 'components/Styles/mobileTable'

export const ValueText = styled.div<{ positive: boolean }>`
  font-size: 14px;
  text-align: left;
  color: ${({ theme, positive }) => (positive ? theme.deprecated_green1 : theme.deprecated_red2)};
`

export const ValueTextMinor = styled.div<{ positive: boolean }>`
  font-size: 12px;
  text-align: left;
  opacity: 0.7;
  color: ${({ theme, positive }) => (positive ? theme.deprecated_green1 : theme.deprecated_red2)};
`

export const ChangeRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`
export const NewValWithArrow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const SimpleCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

const PnLCell = styled.div<{ pos: boolean }>`
  text-align: left;
  ${({ pos }) =>
    pos
      ? `
    color: #4ADE80;
    `
      : `
  color: #EF4444;
  `}
`

const StyledLogo = styled.img`
  width: 15px;
  height: 15px;
`

const ExplorerContainer = styled.div`
  height: 32px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const ExplorerLinkWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
  cursor: pointer;

  :hover {
    opacity: ${({ theme }) => theme.opacity.hover};
  }
  :active {
    opacity: ${({ theme }) => theme.opacity.click};
  }
`

const ExplorerLinkIcon = styled(LinkIconFeather)`
  height: 16px;
  width: 18px;
  margin-left: 8px;
`

const LinkIconWrapper = styled.div`
  justify-content: center;
  display: flex;
`

export function ExternalLinkIcon() {
  return (
    <LinkIconWrapper>
      <ExplorerLinkIcon />
    </LinkIconWrapper>
  )
}

function ExplorerView({ chainId, address, narrow }: { chainId: number; address: string; narrow?: boolean }) {
  if (address) {
    const explorerLink = getExplorerLink(chainId, address, ExplorerDataType.ADDRESS)
    return (
      <ExplorerContainer style={Boolean(narrow) ? { marginLeft: '-10px', width: '50px' } : {}}>
        <ExplorerLinkWrapper onClick={() => window.open(explorerLink, '_blank')}>
          <ExternalLinkIcon />
          {/* <CopyLinkIcon toCopy={explorerLink} /> */}
        </ExplorerLinkWrapper>
      </ExplorerContainer>
    )
  } else {
    return null
  }
}

const PnLCellUSD = styled(PnLCell)`
  opacity: 0.7;
  font-size: 10px;
`
const ResponsiveCheck = styled(Check)``

export function ButtonRadioChecked({
  active,
  onClick,
  isMobile,
}: {
  active: boolean
  onClick: () => void
  isMobile: boolean
}) {
  const theme = useTheme()

  return (
    <CheckboxWrapper onClick={onClick}>
      <Circle active={active} isMobile={isMobile}>
        <ResponsiveCheck size={13} stroke={theme.deprecated_white} />
      </Circle>
    </CheckboxWrapper>
  )
}

export interface SlotData {
  pair: [SupportedAssets, SupportedAssets]
  leverage: number
  direction: Mode
  pnl: number
  healthFactor: number
  price: number
  size: number
  rewardApr: number
  supplyApr: number
  borrowApr: number
}

const LiqPriceText = styled(MetricValue)`
  color: #fbbf24;
`

const TimeText = styled.div`
  text-align: left;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 12px;
  font-weight: 300;
`

const DateText = styled(TimeText)`
  font-weight: 400;
`

const AprText = styled.div<{ pos: boolean }>`
  color: ${({ theme }) => theme.textSecondary};
  font-size: 14px;
  ${({ pos }) =>
    pos
      ? `
    color: #4ADE80;
    `
      : `
  color: #EF4444;
  `}
`

const td = (date: Date) => {
  return (
    date.toLocaleDateString('en-US', { day: 'numeric' }) +
    '-' +
    date.toLocaleDateString('en-US', { month: 'short' }) +
    '-' +
    date.toLocaleDateString('en-US', { year: 'numeric' })
  )
}

export interface PositionProps extends ExtendedSlot {
  index: number
  isMobile: boolean
  selectSlot: () => void
  topSep?: boolean
}

export default function PositionRowMobile(props: PositionProps) {
  const { chainId } = useChainIdAndAccount()

  const aprBorrow = useMemo(() => {
    return `${props.borrowApr.toFixed(props.isMobile ? 2 : 3)}%`
  }, [chainId, props.borrowApr, props.isMobile])

  const aprSupply = useMemo(() => {
    return `${props.supplyApr.toFixed(props.isMobile ? 2 : 3)}%`
  }, [chainId, props.supplyApr, props.isMobile])

  const aprReward = useMemo(() => {
    return `${props.rewardApr.toFixed(props.isMobile ? 2 : 3)}%`
  }, [chainId, props.rewardApr, props.isMobile])

  const isLive = props.closeTime === 0

  return (
    <PositionMRow>
      <PoisitionHeader>
        <Metric
          style={{
            flex: 1,
          }}
        >
          <MetricTitle>Symbol</MetricTitle>
          <PairPositionRow
            pair={props.pair}
            isMobile={props.isMobile}
            leverage={props.leverage}
            direction={props.direction}
          />
        </Metric>
        <CloseButtonWrapper>
          {isLive && <CloseButton onClick={props.selectSlot}>Close Position</CloseButton>}
          <ExplorerView address={props.slot} chainId={chainId} narrow />
        </CloseButtonWrapper>
      </PoisitionHeader>
      <MetricsRow>
        <Metric>
          <MetricTitle>Portfolio size</MetricTitle>
          <MetricValue>{formatSmallUSDValue(props.size)}</MetricValue>
        </Metric>
        <Metric>
          <MetricTitle>Entry Price</MetricTitle>
          <MetricValue>{formatPriceString(String(props.entryPrice))}</MetricValue>
        </Metric>
        {isLive && (
          <Metric>
            <MetricTitle>PnL</MetricTitle>
            <SimpleCol>
              <PnLCell pos={props.pnl > 0}>
                {props.pnl > 0 ? '+' : ''}
                {formatPercent(props.pnl, 2)}
              </PnLCell>
              <PnLCellUSD pos={props.pnl > 0}>
                {props.pnl > 0 ? '+' : ''}
                {formatSmallGeneralUSDValue(props.pnl * props.originalSize)}
              </PnLCellUSD>
            </SimpleCol>
          </Metric>
        )}
        <Metric>
          <MetricTitle>{isLive ? 'Market Price' : 'Exit Price'}</MetricTitle>
          <MetricValue>{formatPriceString(String(isLive ? props.price : props.exitPrice))}</MetricValue>
        </Metric>
        {isLive && (
          <Metric>
            <MetricTitle>Liq. Price</MetricTitle>
            <LiqPriceText>
              {isNaN(props.liquidationPrice) ? '-' : formatPriceString(String(props.liquidationPrice))}
            </LiqPriceText>
          </Metric>
        )}
        <Metric>
          <MetricTitle>Open</MetricTitle>
          <SimpleCol>
            <TimeText>{new Date(props.creationTime * 1000).toLocaleTimeString()}</TimeText>
            <DateText>{td(new Date(props.creationTime * 1000))}</DateText>
          </SimpleCol>
        </Metric>
        {!isLive && (
          <Metric>
            <MetricTitle>Close</MetricTitle>
            <SimpleCol>
              <TimeText>{new Date(props.closeTime * 1000).toLocaleTimeString()}</TimeText>
              <DateText>{td(new Date(props.closeTime * 1000))}</DateText>
            </SimpleCol>
          </Metric>
        )}
        {isLive && (
          <Metric style={{ gap: 6, minWidth: 180 }}>
            <MetricTitle>APR</MetricTitle>
            <RewardsRow>
              {SHOW_VIX_REWARDS && (
                <RewardItem>
                  <StyledLogo src={ovixStandalone} />
                  <AprText pos>+{aprReward}</AprText>
                </RewardItem>
              )}
              <RewardItem>
                <StyledLogo src={TOKEN_SVGS[props.pair[props.direction === Mode.LONG ? 0 : 1]]} />
                <AprText pos>+{aprSupply}</AprText>
              </RewardItem>
              <RewardItem>
                <StyledLogo src={TOKEN_SVGS[props.pair[props.direction === Mode.LONG ? 1 : 0]]} />
                <AprText pos={false}>-{aprBorrow}</AprText>
              </RewardItem>
            </RewardsRow>
          </Metric>
        )}
      </MetricsRow>
    </PositionMRow>
  )
}
