import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { signERC2612Permit } from 'eth-permit'
import { Currency, CurrencyAmount } from '@uniswap/sdk-core'

export function usePermit(amountToApprove?: CurrencyAmount<Currency>, spender?: string) {
  const { provider, account } = useWeb3React()

  const signPermit = useCallback(async (): Promise<any | void> => {
    if (!provider || !account || !spender || !amountToApprove) return

    const deadline = Date.now() + 3600
    const token = amountToApprove?.currency?.isToken ? amountToApprove.currency : undefined

    const result = await signERC2612Permit(
      window.ethereum,
      token?.address || '',
      account,
      spender,
      amountToApprove.quotient.toString(),
      deadline
    )

    return { signature: result, deadline }
  }, [account, amountToApprove, spender])

  return { signPermit }
}
