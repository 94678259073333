import { ButtonPrimary } from 'components/Button'
import Modal from 'components/Modal'
import { SupportedChainId } from 'constants/chains'
import useSelectChain from 'hooks/useSelectChain'
import { AlertCircle } from 'react-feather'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  align-items: center;
  width: 100%;
`

const UnsupportedModal = ({ isOpen, onDismiss }: { isOpen: boolean; onDismiss?: () => void }) => {
  const selectChain = useSelectChain()
  return (
    <Modal isOpen={isOpen} onDismiss={onDismiss} maxHeight={90}>
      <Wrapper>
        <AlertCircle width={40} height={40} color="#FF4343" />
        <p
          style={{
            fontSize: 18,
            fontWeight: 600,
          }}
        >
          Unsupported network
        </p>
        <p
          style={{
            marginTop: 0,
            opacity: 0.7,
          }}
        >
          KEOM currently supports Polygon zkEVM
        </p>
        <ButtonPrimary onClick={() => selectChain(SupportedChainId.POLYGON_ZK_EVM)} height="40px">
          Switch to Polygon zkEVM
        </ButtonPrimary>
      </Wrapper>
    </Modal>
  )
}

export default UnsupportedModal
